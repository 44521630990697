<template>
  <div class="relative flex basis-full items-center">
    <div class="container mx-auto grid grid-cols-1 px-2 md:px-0 lg:grid-cols-2">
      <div class="flex flex-col lg:pr-14">
        <div
          v-for="link in links"
          :key="link.href"
          @mouseenter="activeLinkHref = link.href"
          @mouseleave="activeLinkHref = defaultLinkHref"
        >
          <NuxtLink
            :href="link.href"
            :external="link.external"
            :target="link.external ? '_blank' : undefined"
            class="hover:text-shadow flex items-center gap-2 border-b border-dashed border-black border-opacity-20 py-5 pl-2 font-Kontesa text-h2-mobile font-bold uppercase text-navy transition-all hover:text-white sm:text-h3 xl:mr-32"
          >
            <NuxtImg
              v-if="activeLinkHref && activeLinkHref === link.href"
              v-motion-slide-left
              src="/images/navbar/link-arrow.png"
              :width="136"
              alt="link arrow icon"
              class="hidden w-[31px] 2xl:block"
            />
            {{ link.name }}
          </NuxtLink>
        </div>
      </div>
    </div>
    <div
      class="absolute -right-[10%] top-[30%] hidden aspect-video w-[60%] max-w-3xl lg:block xl:top-[24%] 2xl:left-1/2 2xl:right-auto 2xl:top-[20%]"
    >
      <Transition name="img-fade" mode="out-in">
        <NuxtImg
          v-if="activeLink"
          :key="activeLink.href"
          sizes="md:50vw lg:70vw"
          class="h-full w-full rounded-3xl object-cover shadow-base"
          :src="activeLink.thumbnail"
          format="webp"
          :alt="activeLink.name + ' thumbnail'"
        />
        <NuxtImg
          v-else
          sizes="md:50vw lg:70vw"
          class="h-full w-full rounded-3xl object-cover shadow-base"
          src="images/navbar/thumbnails/about-us.png"
          format="webp"
          alt="about us thumbnail"
        />
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
const { getNavRoutes, Routes } = useRouteUtils();
const links = getNavRoutes();

const defaultLinkHref = Routes.ABOUT as string;
const activeLinkHref = ref(defaultLinkHref);

const activeLink = computed(() => {
  return links.value.find((link) => link.href === activeLinkHref.value);
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 150ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(-50%);
}

.img-fade-enter-active {
  transition: all 350ms ease;
}

.img-fade-enter-from {
  transform: translateX(100%);
}
</style>
