<template>
  <div
    class="z-50 backdrop-blur-sm transition-all duration-300"
    :class="{ 'bg-[#fee6ee]': !isUserScrollingDown && isSimple }"
  >
    <div
      class="mx-auto flex items-center justify-between gap-3 transition-all duration-300"
      :class="[
        isContainerDefault ? 'container' : 'container-md',
        isUserScrollingDown || isSimple
          ? 'h-16  p-1'
          : 'h-16 p-1 lg:h-32 lg:px-2 lg:py-2',
        isUserScrollingDown && isSimple ? 'opacity-50' : 'opacity-100',
      ]"
    >
      <DialogRoot v-model:open="isOpen">
        <Transition name="open">
          <DialogTrigger
            v-if="!isOpen"
            class="flex h-[46px] w-16 items-center justify-center rounded-full border-dashed border-navy bg-yellow transition-all hover:border hover:bg-nature"
            aria-label="open menu"
            @click="isOpen = true"
          >
            <IconsMenu />
          </DialogTrigger>
        </Transition>

        <DialogPortal>
          <Transition name="slide-left">
            <DialogOverlay
              class="fixed inset-0 z-50 overflow-y-auto overflow-x-hidden bg-yellow"
            >
              <DialogContent class="min-h-dvh">
                <DialogTitle class="sr-only">Navigation menu</DialogTitle>
                <DialogDescription class="sr-only">
                  Click a link to be taken to the respective page of the website
                </DialogDescription>
                <div
                  class="container mx-auto flex items-center justify-end px-6 py-2"
                >
                  <Transition name="close">
                    <DialogClose
                      v-if="isOpen"
                      class="flex h-[46px] w-16 items-center justify-center rounded-full bg-white"
                      aria-label="close menu"
                      @click="isOpen = false"
                    >
                      <IconsClose />
                    </DialogClose>
                  </Transition>
                </div>
                <AppHeaderDrawer />
              </DialogContent>
            </DialogOverlay>
          </Transition>
        </DialogPortal>
      </DialogRoot>
      <NuxtLink class="center-icon z-10" :href="Routes.HOME">
        <!-- <PigLogoAnimation
          class="top-0 left-0 bottom-0 right-0"
        /> -->
        <div v-if="!isUserScrollingDown && !isSimple" class="w-full">
          <NuxtImg
            src="/images/logo.png"
            sizes="30vw md:10vw"
            class="absolute left-1/2 top-1/2 hidden h-[64px] w-[64px] -translate-x-1/2 -translate-y-1/2 object-contain md:h-[113px] md:w-[113px] lg:block"
            format="webp"
            alt="site logo"
          />
          <span
            class="font-Kontesa text-[21px] font-bold leading-tight -tracking-[0.03em] text-navy sm:text-[29px] lg:hidden"
          >
            DOLLAR DONATION CLUB
          </span>
        </div>
        <div
          v-else
          class="flex items-center gap-[14px] lg:absolute lg:left-1/2 lg:top-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2"
        >
          <NuxtImg
            src="/images/logo-icon-small.png"
            sizes="30vw md:10vw"
            class="hidden h-[64px] w-[64px] -rotate-6 object-contain lg:block"
            format="webp"
            alt="site logo"
          />
          <span
            class="font-Kontesa text-[21px] font-bold leading-tight -tracking-[0.03em] text-navy sm:text-[29px]"
          >
            DOLLAR DONATION CLUB
          </span>
        </div>
      </NuxtLink>

      <div class="ml-auto flex items-center gap-2">
        <NuxtLink
          external
          target="_blank"
          to="https://store.dollardonationclub.com"
          class="hidden shrink-0 rounded-full border border-dashed border-transparent px-5 py-2.5 font-medium text-navy transition-all hover:border-navy hover:bg-nature md:block"
        >
          Store
        </NuxtLink>
        <NuxtLink
          v-if="self"
          to="/profile"
          class="flex min-w-max items-center gap-2 rounded-full border border-dashed border-transparent bg-yellow px-3 py-1.5 text-[15px] font-medium text-navy transition-all hover:border-navy hover:bg-nature md:text-base"
        >
          <NuxtImg
            class="inline h-8 w-8 overflow-hidden rounded-full border border-dashed border-navy"
            :src="self.avatarUrl ?? '/images/avatars/default-avatar.jpg'"
            alt="avatar icon"
            :width="32"
            :height="32"
            format="webp"
          />
          Profile
        </NuxtLink>
        <NuxtLink
          v-else
          to="/login"
          class="hidden shrink-0 rounded-full border border-dashed border-transparent px-5 py-2.5 font-medium text-navy transition-all hover:border-navy hover:bg-nature md:block"
        >
          Login
        </NuxtLink>

        <NuxtLink
          v-if="!self"
          :to="joinUrl"
          class="shrink-0 rounded-full border border-dashed border-transparent bg-yellow px-5 py-2.5 font-bold uppercase text-navy transition-all hover:border-navy hover:bg-nature"
        >
          Join
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from "radix-vue";
import { useWindowScroll } from "@vueuse/core";
import { useSelf } from "~/domains/member/composables";

const props = defineProps<{
  isSimple?: boolean;
}>();

const { Routes } = useRouteUtils();
const isOpen = ref(false);
const route = useRoute();

const { data: self } = useSelf();

const { y } = useWindowScroll();
const isUserScrollingDown = ref(props.isSimple ?? false);

watch(y, () => {
  // if (props.isSimple) isUserScrollingDown.value = true;
  // else
  y.value > 0.1
    ? (isUserScrollingDown.value = true)
    : (isUserScrollingDown.value = false);
});

const router = useRouter();
router.afterEach(() => {
  isOpen.value = false;
});

const isContainerDefault = computed(
  () => route.path !== Routes.ABOUT && route.path !== Routes.FOR_BUSINESS,
);

const joinUrl = computed(() => {
  if (route.fullPath === "/campaigns/trees-for-the-future") {
    return Routes.SIGN_UP + "?campaignId=3";
  } else if (route.fullPath === "/campaigns/plastic-fischer") {
    return Routes.SIGN_UP + "?campaignId=5";
  } else if (route.fullPath === "/campaigns/ocean-voyages-institute") {
    return Routes.SIGN_UP + "?campaignId=4";
  } else if (route.fullPath === "/campaigns/yellow-rooms") {
    return Routes.SIGN_UP + "?campaignId=6";
  } else if (route.fullPath === "/campaigns/cleanhub") {
    return Routes.SIGN_UP + "?campaignId=8";
  } else if (route.matched.some((m) => m.path === "/for-business")) {
    return "/for-business/sign-up";
  }

  return Routes.SIGN_UP;
});
</script>

<style scoped lang="scss">
.open-enter-active,
.open-leave-active {
  transition: all 400ms ease;
}

.open-enter-from,
.open-leave-to {
  opacity: 0;
  transform: translateX(-50%);
}

.close-enter-active,
.close-leave-active {
  transition: all 150ms ease;
}

.close-enter-from,
.close-leave-to {
  opacity: 0;
  transform: translateX(50%);
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 500ms ease-in-out;
}

.slide-left-enter-from,
.slide-left-leave-to {
  transform: translateX(100%);
}
</style>
